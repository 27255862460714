import React from 'react';
import {ContentCardWithMeta} from 'components/cards/contentCardWithMeta'


export const VbsCard = (props)=>{
  let image = null;
  let date = null
  if(props.featured_media && props.featured_media.localFile && props.featured_media.localFile.childImageSharp && props.featured_media.localFile.childImageSharp.fluid){
    image = props.featured_media.localFile.childImageSharp.fluid
  }
  if(props.vbs_types && props.vbs_types.length){
    date = props.vbs_types[0].name
  }
  return(
    <div >
      <ContentCardWithMeta
        {...props}
        image={image}
        date={date}
        link={{url: props.path}}
      />
    </div>
  )
}
