import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { NewsContent, Header } from "components/news"
import { ContentWithShare } from "components/ContentWithShare"
import { Banner } from "components/banners"
import Section from "components/section"
import { withGrid } from "components/grid"
import { VbsCard } from "components/vbs/parts/card"
import Styles from "./vbs.module.css"
import { BgPattern } from "components/backgrounds"
import BgStyles from "components/backgrounds/patterns.module.css"
const SingleVbsTemplate = props => {
	const data = props.data.wordpressWpVbs
	const image =
		data.featured_media &&
		data.featured_media.localFile &&
		data.featured_media.localFile.childImageSharp &&
		data.featured_media.localFile.childImageSharp.desktop
			? data.featured_media.localFile.childImageSharp.desktop
			: null
	let VbsGrid = null
	if (props.data.relatedVbs && props.data.relatedVbs.nodes.length) {
		VbsGrid = withGrid(VbsCard, props.data.relatedVbs.nodes)
	}
	return (
		<Layout
			articleId={"vbs-" + data.slug}
			articleClassName={data.classes}
			context={props.pageContext}
			location={props.location}
		>
			<header className="mb-10">
				<Banner image={image} />
				<div className={Styles.headerContainer}>
					<h1
						className={Styles.title}
						dangerouslySetInnerHTML={{ __html: data.title }}
					/>
					<p className={Styles.date}>{props.date}</p>
					<div className="flex flex-wrap">
						{data.tags &&
							data.tags.length > 0 &&
							data.tags.map((tag, index) => {
								const show_sep = index < data.tags.length - 1
								return (
									<div className={Styles.tag}>
										<span dangerouslySetInnerHTML={{ __html: tag.name }} />
										{show_sep && <p className={Styles.sep}>-</p>}
									</div>
								)
							})}
					</div>
				</div>
			</header>
			<div className="container mx-auto entry">
				<ContentWithShare twitter={true} facebook={true} linkedin={true}>
					<div dangerouslySetInnerHTML={{ __html: data.content }} />
				</ContentWithShare>
			</div>
			<footer
				className="entry-footer mt-32 container mx-auto"
				style={{ maxWidth: 1170 }}
			>
				{VbsGrid && (
					<Section title="Related Projects">
						<BgPattern className={`${BgStyles.pattern1} ${Styles.bgRelated}`} />
						<VbsGrid />
					</Section>
				)}
			</footer>
		</Layout>
	)
}

export default SingleVbsTemplate

export const vbsQuery = graphql`
	query vbsById($id: String!, $type_ids: [String!]) {
		wordpressWpVbs(id: { eq: $id }) {
			title
			content
			path
			slug
			type
			classes
			date(formatString: "LL")
			wordpress_id
			tags: vbs_types {
				name
			}
			featured_media {
				localFile {
					...PageBannerImages
				}
			}
		}
		relatedVbs: allWordpressWpVbs(
			filter: {
				vbs_types: { elemMatch: { id: { in: $type_ids } } }
				id: { ne: $id }
			}
			sort: { fields: menu_order }
		) {
			nodes {
				id
				path
				title
				regions {
					name
					id
				}
				countries {
					name
					id
					parent_element {
						id
					}
				}
				crops {
					name: title
					id
				}
				vbs_types {
					name
					id
				}
				featured_media {
					localFile {
						childImageSharp {
							fluid(maxWidth: 370) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	}
`
